/* eslint-disable complexity */

import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import {
    type SocialMediaSingleHighlight,
    type SocialMediaSinglePostActivity,
    type SocialMediaSinglePostFormat,
    type SocialMediaSinglePostsList,
    type SocialMediaSingleTopAndWeakPosts
} from '@shared/interfaces/social-media';
import {
    InstagramTotalInteractions,
    SocialMediaSingleAllPosts,
    SocialMediaSingleFollowersGrowth,
    SocialMediaSingleImpressions,
    SocialMediaSingleReachAndImpressions,
    SocialMediaSingleReachByPost,
    SocialMediaSingleStoriesResults,
    SocialMediaSingleTwitterImpressionsHistory,
    SocialMediaSingleYoutubeVideosShortsViews,
    type SocialMediaSingleActiveProfiles,
    type SocialMediaSingleEngagement,
    type SocialMediaSingleInteraction,
    type SocialMediaSingleInteractionByPost,
    type SocialMediaSingleReach
} from '@shared/interfaces/social-media/socialMediaSingle';

import { DataStatus } from '@shared/enum/dataStatus';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { DatePickerItem } from '@shared/interfaces/store/datePicker';

import { neitherNullNorUndefined } from '@services/validation/validation';
import { SocialPostTypes } from '@shared/enum';
import { RootState } from '@store/storeConfig';
import { formatSingleInteraction, formatSinglePostActivity } from './SocialMediaSingle.formatter';
import { fetchSocialMediaSingle, fetchSocialMediaSingleActiveProfiles, fetchSocialMediaSingleAllPosts, fetchSocialMediaSingleAllStories, fetchSocialMediaSinglePost, fetchSocialMediaSingleStory } from './SocialMediaSingle.thunk';

export declare interface SocialMediaOverviewState {
    activeProfiles: SocialMediaSingleActiveProfiles;
    activeProfilesStatus: DataStatus;
    activeProfilesError: string | null;

    networkType: SocialMediaTypes;
    profileName: string;
    url: string;

    channelFollowers: {
        difference: number;
        followers: number;
        gain: number;
        loss: number;
    };
    channelContent: {
        avgViewDuration: number;
        totalVideo: number;
        totalViews: number;
        totalWatchTime: number;
    };
    avgInteractionsPerDay: {
        comments: number;
        intercationsPerDay: number;
        likeDislikeRatio: string;
        shares: number;
    };
    lastVideoPerformance: {
        comments: number;
        date: string;
        engagementRate: number;
        id: number;
        image: string;
        interaction: string;
        link: string;
        message: string;
        reference: string;
        type: SocialPostTypes;
        views: number;
    };
    dislike: {
        value: number;
        pastValue: number;
        percentage: number;
    };

    followers: number;
    pageViews: number;
    pageViewsPerDay: number;
    overAll: number;

    totalTweets: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    likes: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    shares: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    comments: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    followersEvolution: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    profileFollowersGain: number;
    profileFollowersLost: number;
    posts: SocialMediaSingleHighlight;
    postInteraction: SocialMediaSingleHighlight;
    postPerDay: SocialMediaSingleHighlight;
    engagementRate: SocialMediaSingleEngagement['engagementRate'];
    topAndWeakPosts: SocialMediaSingleTopAndWeakPosts;
    postList: SocialMediaSinglePostsList;
    interaction: SocialMediaSingleInteraction;
    storiesResults: SocialMediaSingleStoriesResults;

    followerGrowth: SocialMediaSingleFollowersGrowth;
    postFormat: SocialMediaSinglePostFormat;
    postActivity: SocialMediaSinglePostActivity;
    interactionByPost: SocialMediaSingleInteractionByPost;
    reachByPostType: SocialMediaSingleReachByPost;
    impressions: SocialMediaSingleImpressions;
    impression: number;
    engagement: SocialMediaSingleEngagement;
    reach: SocialMediaSingleReach;
    reachAndImpression: SocialMediaSingleReachAndImpressions;
    impressionsHistory: SocialMediaSingleTwitterImpressionsHistory;
    totalInteractions: InstagramTotalInteractions;
    videoAndShortsView: SocialMediaSingleYoutubeVideosShortsViews;

    allPosts: SocialMediaSingleAllPosts;
    allPostsStatus: DataStatus;
    allPostsError: string | null;

    activePost: any;
    activePostStatus: DataStatus;
    activePostError: string | null;

    allStories: SocialMediaSingleAllPosts;
    allStoriesStatus: DataStatus;
    allStoriesError: string | null;

    activeStory: any;
    activeStoryStatus: DataStatus;
    activeStoryError: string | null;

    status: DataStatus;
    error: string | null;
}

const convertHighlightToNumber = (highlight: {
    total: string;
    percentage: string;
    difference: string;
    sparklines?: string[];
}): SocialMediaSingleHighlight => {
    const chart = highlight?.sparklines?.length ? {
        chart: highlight.sparklines.map((value) => Number(value))
    } : { chart: [] };

    return {
        total: highlight?.total ?? '0',
        pastTotal: null,
        percentage: highlight?.percentage ?? '0',
        difference: highlight?.difference ?? '0',
        ...chart
    };
};

const initialHighlight                       = {
    total: '0',
    pastTotal: null,
    percentage: '0',
    difference: '0',
};
const initialPostFormat                      = {
    total: 0,
};
const initialState: SocialMediaOverviewState = {
    activeProfiles: [],
    activeProfilesStatus: DataStatus.IDLE,
    activeProfilesError: null,

    networkType: SocialMediaTypes.UNKNOWN,
    profileName: '',
    url: '',
    channelFollowers: null,
    channelContent: null,
    avgInteractionsPerDay: null,
    lastVideoPerformance: null,
    dislike: null,
    followers: 0,
    pageViews: 0,
    pageViewsPerDay: 0,
    overAll: 0,
    totalTweets: null,
    likes: null,
    shares: null,
    comments: null,
    followersEvolution: null,
    profileFollowersGain: 0,
    profileFollowersLost: 0,
    posts: null,
    postInteraction: {
        ...initialHighlight,
        chart: [],
    },
    interactionByPost: null,
    reachByPostType: null,
    postPerDay: {
        ...initialHighlight,
        chart: [],
    },
    engagementRate: null,
    followerGrowth: null,
    postFormat: initialPostFormat,
    postActivity: null,
    interaction: null,
    storiesResults: null,
    topAndWeakPosts: {
        top: [],
        weak: []
    },
    postList: [],
    impressions: null,
    impression: null,
    engagement: null,
    reachAndImpression: null,
    reach: null,
    totalInteractions: null,
    impressionsHistory: null,
    videoAndShortsView: null,

    allPosts: null,
    allPostsStatus: DataStatus.IDLE,
    allPostsError: null,

    activePost: null,
    activePostStatus: DataStatus.IDLE,
    activePostError: null,

    allStories: null,
    allStoriesStatus: DataStatus.IDLE,
    allStoriesError: null,

    activeStory: null,
    activeStoryStatus: DataStatus.IDLE,
    activeStoryError: null,

    error: null,
    status: DataStatus.IDLE,
};

export const socialMediaSingleSlice = createSlice({
    name: 'socialMediaSingle',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            // all single
            .addCase(fetchSocialMediaSingle.pending, (state) => {
                state.status = DataStatus.LOADING;
                state.error  = null;
            })
            .addCase(fetchSocialMediaSingle.fulfilled, (state, action) => {
                state.networkType = action.payload.networkType ?? SocialMediaTypes.UNKNOWN;
                state.profileName = action.payload.profileName ?? 'N/A';
                state.url         = action.payload.url ?? '';

                const followers = action.payload?.followers;

                if (neitherNullNorUndefined((followers as unknown as Record<string,any>)?.followers)) {
                    state.channelFollowers = followers as {
                        difference: number;
                        followers: number;
                        gain: number;
                        loss: number;
                    };
                } else {
                    state.followers = followers as number;
                }

                state.channelContent        = action.payload?.content;
                state.avgInteractionsPerDay = action.payload?.avgInteractionsPerDay;
                state.lastVideoPerformance  = action.payload?.lastVideoPerformance;

                const dislikes          = action.payload?.dislike;

                state.dislike = {
                    value: Number(dislikes?.total || dislikes?.value || 0),
                    pastValue: Number(dislikes?.pastValue || 0),
                    percentage: Number(dislikes?.percentage || 0),
                };

                state.pageViews       = action.payload?.pageViews;
                state.pageViewsPerDay = action.payload?.pageViewsPerDay;
                state.overAll         = action.payload?.overAll;

                state.storiesResults = action.payload.storiesResults;

                const totalTweets          = action.payload?.totalTweets;

                state.totalTweets = {
                    value: Number(totalTweets?.total || totalTweets?.value || 0),
                    pastValue: Number(totalTweets?.pastValue || 0),
                    percentage: Number(totalTweets?.percentage || 0),
                };

                const likes          = action.payload?.likes ?? action.payload?.favorites;

                state.likes                = {
                    value: Number(likes?.total || likes?.value || 0),
                    pastValue: Number(likes?.pastValue || 0),
                    percentage: Number(likes?.percentage || 0),
                };
                state.comments             = action.payload?.comments ?? action.payload?.replies;
                state.shares               = action.payload?.shares ?? action.payload?.retweetAndShare;
                state.followersEvolution   = action.payload?.followersEvolution ?? {
                    value: action.payload?.followerEvolution,
                    pastValue: null,
                    percentage: null,
                };
                state.profileFollowersGain = action.payload?.profileFollowersGain;
                state.profileFollowersLost = action.payload?.profileFollowersLost;
                state.posts                = convertHighlightToNumber(action.payload?.posts);
                state.postInteraction      = convertHighlightToNumber(action.payload?.postInteraction);
                state.postPerDay           = convertHighlightToNumber(action.payload?.postPerDay);
                state.followerGrowth       = action.payload?.followerGrowth;
                state.postFormat           = isEmpty(action.payload?.postFormat) ? initialPostFormat : {
                    total: Number(action.payload.postFormat?.total) ?? 0,
                    ...action.payload.postFormat,
                };

                const dates     = [action.meta.arg.data.start_date, action.meta.arg.data.end_date] as DatePickerItem;
                const pastDates = [action.meta.arg.data.compareFrom, action.meta.arg.data.compareTo] as DatePickerItem;

                state.postActivity       = formatSinglePostActivity(action.payload?.postActivity, dates, pastDates);
                state.interaction        = formatSingleInteraction(action.payload?.interaction, dates, pastDates);
                state.interactionByPost  = action.payload?.interactionByPost ?? null;
                state.totalInteractions  = action.payload?.totalInteractions ?? null;
                state.videoAndShortsView = action.payload?.videoAndShortsView ?? null;
                state.topAndWeakPosts    = action.payload.topAndWeakPosts ?? initialState.topAndWeakPosts;
                state.postList           = action.payload.postList ?? [];
                state.engagement         = action.payload.engagement ?? null;
                state.engagementRate     = action.payload?.engagement?.engagementRate ?? null;
                state.impressions        = action.payload?.impressions ?? action.payload?.impression  ?? null;
                state.reachAndImpression = action.payload?.ReachAndImpression ?? action.payload?.reachAndImpression ?? null;
                state.impressionsHistory = action.payload?.impressionsHistory ?? null;
                state.reachByPostType    = action.payload.reachByPostType ?? action.payload?.impressionsByPost ?? null;
                state.reach              = action.payload.reach ?? null;
                state.status             = DataStatus.SUCCESS;
                state.error              = null;
            })
            .addCase(fetchSocialMediaSingle.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.status = DataStatus.IDLE;

                    return;
                }

                state.error  = action.payload as unknown as string;
                state.status = DataStatus.FAILED;
            })
            // active profiles
            .addCase(fetchSocialMediaSingleActiveProfiles.pending, (state) => {
                state.activeProfilesStatus = DataStatus.LOADING;
                state.activeProfilesError  = null;
            })
            .addCase(fetchSocialMediaSingleActiveProfiles.fulfilled, (state, action) => {
                state.activeProfiles       = action.payload;
                state.activeProfilesStatus = DataStatus.SUCCESS;
                state.activeProfilesError  = null;
            })
            .addCase(fetchSocialMediaSingleActiveProfiles.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.status = DataStatus.IDLE;

                    return;
                }

                state.activeProfilesError  = action.payload as unknown as string;
                state.activeProfilesStatus = DataStatus.FAILED;
            })
            // fetch all posts
            .addCase(fetchSocialMediaSingleAllPosts.pending, (state) => {
                state.allPostsStatus = DataStatus.LOADING;
                state.allPostsError  = null;
            })
            .addCase(fetchSocialMediaSingleAllPosts.fulfilled, (state, action) => {
                state.allPosts       = action.payload;
                state.allPostsStatus = DataStatus.SUCCESS;
                state.allPostsError  = null;
            })
            .addCase(fetchSocialMediaSingleAllPosts.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.allPostsStatus = DataStatus.IDLE;

                    return;
                }

                state.allPostsError  = action.payload as unknown as string;
                state.allPostsStatus = DataStatus.FAILED;
            })
            // fetch post
            .addCase(fetchSocialMediaSinglePost.pending, (state) => {
                state.activePostStatus = DataStatus.LOADING;
                state.activePostError  = null;
            })
            .addCase(fetchSocialMediaSinglePost.fulfilled, (state, action) => {
                state.activePost       = action.payload;
                state.activePostStatus = DataStatus.SUCCESS;
                state.activePostError  = null;
            })
            .addCase(fetchSocialMediaSinglePost.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.activePostStatus = DataStatus.IDLE;

                    return;
                }

                state.activePostError  = action.payload as unknown as string;
                state.activePostStatus = DataStatus.FAILED;
            })


            // fetch all stories
            .addCase(fetchSocialMediaSingleAllStories.pending, (state) => {
                state.allStoriesStatus = DataStatus.LOADING;
                state.allStoriesError  = null;
            })
            .addCase(fetchSocialMediaSingleAllStories.fulfilled, (state, action) => {
                state.allStories       = action.payload;
                state.allStoriesStatus = DataStatus.SUCCESS;
                state.allStoriesError  = null;
            })
            .addCase(fetchSocialMediaSingleAllStories.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.allStoriesStatus = DataStatus.IDLE;

                    return;
                }

                state.allStoriesError  = action.payload as unknown as string;
                state.allStoriesStatus = DataStatus.FAILED;
            })
            // fetch story
            .addCase(fetchSocialMediaSingleStory.pending, (state) => {
                state.activeStoryStatus = DataStatus.LOADING;
                state.activeStoryError  = null;
            })
            .addCase(fetchSocialMediaSingleStory.fulfilled, (state, action) => {
                state.activeStory       = action.payload;
                state.activeStoryStatus = DataStatus.SUCCESS;
                state.activeStoryError  = null;
            })
            .addCase(fetchSocialMediaSingleStory.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.activeStory = DataStatus.IDLE;

                    return;
                }

                state.activeStoryError  = action.payload as unknown as string;
                state.activeStoryStatus = DataStatus.FAILED;
            });
    },
});

export const {
    clearState
} = socialMediaSingleSlice.actions;
export const selectFromSocialMediaSingle = {
    activeProfiles: (state: RootState) => ({
        activeProfiles: state.socialMediaSingleReducer.activeProfiles,
        status: state.socialMediaSingleReducer.activeProfilesStatus,
        error: state.socialMediaSingleReducer.activeProfilesError,
    }),
    all: (state: RootState) => state.socialMediaSingleReducer,
    info: (state: RootState) => ({
        networkType: state.socialMediaSingleReducer.networkType,
        profileName: state.socialMediaSingleReducer.profileName,
        url: state.socialMediaSingleReducer.url,
    }),
    status: (state: RootState) => state.socialMediaSingleReducer.status,
    error: (state: RootState) => state.socialMediaSingleReducer.error,
    followers: (state: RootState) => state.socialMediaSingleReducer.followers,
    likes: (state: RootState) => state.socialMediaSingleReducer.likes,
    posts: (state: RootState) => state.socialMediaSingleReducer.posts,
    postInteraction: (state: RootState) => state.socialMediaSingleReducer.postInteraction,
    postPerDay: (state: RootState) => state.socialMediaSingleReducer.postPerDay,
    engagementRate: (state: RootState) => state.socialMediaSingleReducer.engagementRate,
    followerGrowth: (state: RootState) => state.socialMediaSingleReducer.followerGrowth,
    postFormat: (state: RootState) => ({
        data: state.socialMediaSingleReducer.postFormat,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    postActivity: (state: RootState) => ({
        data: state.socialMediaSingleReducer.postActivity,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    interaction: (state: RootState) => ({
        data: state.socialMediaSingleReducer.interaction,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    interactionByPost: (state: RootState) => ({
        data: state.socialMediaSingleReducer.interactionByPost,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    reachByPost: (state: RootState) => ({
        data: state.socialMediaSingleReducer.reachByPostType,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    topAndWeakPosts: (state: RootState) => ({
        data: state.socialMediaSingleReducer.topAndWeakPosts,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    postList: (state: RootState) => ({
        data: state.socialMediaSingleReducer.postList,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    engagement: (state: RootState) => ({
        data: state.socialMediaSingleReducer.engagement,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    reach: (state: RootState) => ({
        data: state.socialMediaSingleReducer.reach,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    reachAndImpression: (state: RootState) => ({
        data: state.socialMediaSingleReducer.reachAndImpression,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    impressions: (state: RootState) => ({
        data: state.socialMediaSingleReducer.impressions,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    videoAndShortsView: (state: RootState) => ({
        data: state.socialMediaSingleReducer.videoAndShortsView,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    allPosts: (state: RootState) => ({
        data: state.socialMediaSingleReducer.allPosts,
        status: state.socialMediaSingleReducer.allPostsStatus,
        error: state.socialMediaSingleReducer.allPostsError,
    }),
    activePost: (state: RootState) => ({
        data: state.socialMediaSingleReducer.activePost,
        status: state.socialMediaSingleReducer.activePostStatus,
        error: state.socialMediaSingleReducer.activePostError,
    }),
    allStories: (state: RootState) => ({
        data: state.socialMediaSingleReducer.allStories,
        status: state.socialMediaSingleReducer.allStoriesStatus,
        error: state.socialMediaSingleReducer.allStoriesError,
    }),
    activeStory: (state: RootState) => ({
        data: state.socialMediaSingleReducer.activeStory,
        status: state.socialMediaSingleReducer.activeStoryStatus,
        error: state.socialMediaSingleReducer.activeStoryError,
    }),
};

const socialMediaSingleReducer = socialMediaSingleSlice.reducer;

export default socialMediaSingleReducer;
