
import { createSlice } from '@reduxjs/toolkit';

import { parse } from '@services/conversion/conversion';
import { isObjectEmpty } from '@services/validation/validation';
import { SocialMediaTypes } from '@shared/enum';
import { DataStatus } from '@shared/enum/dataStatus';
import { SocialStatus } from '@shared/enum/socialStatus';
import { SocialMediaOverviewEngagement, SocialMediaOverviewHighlights, SocialMediaOverviewHighlightsItem } from '@shared/interfaces/social-media';
import { RootState } from '@store/storeConfig';
import { isObjectLike } from 'lodash';
import { AppleChart, AppleObjValue, SocialMediaOverviewProfiles, fetchSocialMediaOverview, fetchSocialMediaOverviewEngagement } from './socialMediaOverview.thunk';

interface PostHighlight {
    image: string;
    link: string;
    networkType: SocialMediaTypes;
    value: string;
    thumbnail: string;
}

export declare interface SocialMediaOverviewState {
    socialStatus: SocialStatus;
    engagement: SocialMediaOverviewEngagement;
    engagementError: string | null;
    engagementStatus: DataStatus;

    // profiles: SocialMediaOverviewProfiles;
    // overviewHighlights: SocialMediaOverviewHighlights;
    // overviewEngagement: SocialMediaEngagement;
    // overviewReach: SocialMediaReach;
    // overviewInteractions: SocialMediaInteractions;
    // overviewFollowers: SocialMediaTotalFollowers;
    // overviewPosts: SocialMediaPosts;

    highlights: SocialMediaOverviewHighlights;
    engagementHistory: AppleChart;
    impressionHistory: AppleChart;
    postInteractions: AppleChart;
    mostEngagementPost: PostHighlight;
    mostImpressionPost: PostHighlight;
    leastEngagementPost: PostHighlight;
    leastImpressionPost: PostHighlight;
    profiles: SocialMediaOverviewProfiles;
    totalComments: AppleObjValue;
    totalEngagement: AppleObjValue;
    totalFollowers: AppleChart;
    totalImpression: AppleObjValue;
    totalInteractions: AppleObjValue;
    totalPosts: AppleObjValue;
    totalReactions: AppleObjValue;
    totalShares: AppleObjValue;
    postsReach: AppleObjValue;
    totalEngagementRate: AppleObjValue;

    error: string | null;
    status: DataStatus;

}

const initialState: SocialMediaOverviewState = {
    socialStatus: SocialStatus.IDLE,
    engagement: null,
    engagementError: null,
    engagementStatus: DataStatus.IDLE,

    highlights: null,
    engagementHistory: null,
    impressionHistory: null,
    postInteractions: null,
    mostEngagementPost: null,
    mostImpressionPost: null,
    leastEngagementPost: null,
    leastImpressionPost: null,
    profiles: null,
    totalComments: null,
    totalEngagement: null,
    totalFollowers: null,
    totalImpression: null,
    totalInteractions: null,
    totalPosts: null,
    totalReactions: null,
    totalShares: null,
    postsReach: null,
    totalEngagementRate: null,

    error: null,
    status: DataStatus.IDLE,
};

export const socialMediaOverviewSlice = createSlice({
    name: 'socialMediaOverview',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            // all
            .addCase(fetchSocialMediaOverview.pending, (state) => {
                state.status = DataStatus.LOADING;
            })
            .addCase(fetchSocialMediaOverview.fulfilled, (state, action) => {
                state.engagementHistory   = action?.payload?.engagementHistory;
                state.impressionHistory   = action?.payload?.impressionHistory;
                state.postInteractions    = action?.payload?.postInteractions;
                state.mostEngagementPost  = action?.payload?.mostEngagementPost;
                state.mostImpressionPost  = action?.payload?.mostImpressionPost;
                state.leastEngagementPost = action?.payload?.leastEngagementPost;
                state.leastImpressionPost = action?.payload?.leastImpressionPost;
                state.profiles            = action?.payload?.profiles;
                state.highlights          = isObjectEmpty(action?.payload?.profiles) ? [] : Object.entries(action.payload.profiles).map(
                    ([profileId, profileData]) => ({
                        networkType: profileData.networkType,
                        profileName: profileData.profileName,
                        profileId,
                        isEngagementInPercentage: false,
                        engagement: profileData?.engagement?.value || 0,
                        pastEngagement: profileData?.engagement?.pastValue || null,
                        compare: {
                            percentage: profileData?.engagement?.percentage || 0,
                            diff: 0,
                        },
                    } as SocialMediaOverviewHighlightsItem)
                );
                state.totalComments       = action?.payload?.totalComments;
                state.totalEngagement     = action?.payload?.totalEngagement;
                state.totalFollowers      = action?.payload?.totalFollowers;
                state.totalImpression     = action?.payload?.totalImpression;
                state.totalInteractions   = action?.payload?.totalInteractions;
                state.totalPosts          = action?.payload?.totalPosts;
                state.totalReactions      = action?.payload?.totalReactions;
                state.totalShares         = action?.payload?.totalShares;
                state.postsReach          = action?.payload?.postsReach;
                state.totalEngagementRate = action?.payload?.totalEngagementRate;
                state.status              = DataStatus.SUCCESS;
                state.socialStatus        = SocialStatus.SIGNED;
            })
            .addCase(fetchSocialMediaOverview.rejected, (state, action) => {
                state.socialStatus = SocialStatus.UNSIGNED;
                state.error        = action.payload as unknown as string;
                state.status       = DataStatus.FAILED;
            })
            // engagement
            .addCase(fetchSocialMediaOverviewEngagement.pending, (state) => {
                state.engagementStatus = DataStatus.LOADING;
            })
            .addCase(fetchSocialMediaOverviewEngagement.fulfilled, (state, action) => {
                state.socialStatus     = SocialStatus.SIGNED;
                state.engagement       = {
                    chart: action.payload?.sparklines && isObjectLike(action.payload?.sparklines)
                        ? Object
                            .values(action.payload?.sparklines)
                            .map((n) => parse.intFloat(n, 2))
                        : [],
                    total: Number(action.payload?.total),
                    percentage: parse.intFloat(action.payload?.percentage || 0, 2),
                };
                state.engagementStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchSocialMediaOverviewEngagement.rejected, (state, action) => {
                state.socialStatus     = String(action.payload).toLowerCase() !== 'cancel' ? SocialStatus.UNSIGNED : null;
                state.engagementError  = action.payload as unknown as string;
                state.engagementStatus = DataStatus.FAILED;
            });
    },
});

export const {
    clearState
} = socialMediaOverviewSlice.actions;
export const selectedFromSocialMediaOverview = {
    overviewEngagement: (state: RootState) => ({
        socialStatus: state.socialMediaOverviewReducer.socialStatus,
        data: state.socialMediaOverviewReducer.engagement ?? null,
        error: state.socialMediaOverviewReducer.engagementError,
        status: state.socialMediaOverviewReducer.engagementStatus,
    }),
    profiles: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.profiles ?? null,
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    highlights: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.highlights ?? null,
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    all: (state: RootState) => state.socialMediaOverviewReducer,
};

const socialMediaOverviewReducer = socialMediaOverviewSlice.reducer;

export default socialMediaOverviewReducer;
